<template>
  <layout class="employees" v-load="loading">
    <template #header>
      <h1 class="title-1">Subordinates</h1>
    </template>
    <div class="d-flex align-items-center justify-content-end mb-3">
      <div class="form-group m-0">
        <Search @onChange="handleSearch" :search="filterEmployees.search || ''" />
      </div>
      <router-link v-if="haveSubordinate" to="/dismissal-warnings" class="ml-3">
        <b-button
          variant="danger"
          size="sm"
        >
          Dismissal warnings
        </b-button>
      </router-link>
    </div>
    <div class="card">
      <div class="card-body">
        <b-tabs content-class="tab-container" nav-class="nav-tabs-custom mb-4" v-model="tabIndex">
          <b-tab v-for="(item, idx) in tabsComponents" :key="idx" :active="tabIndex === idx">
            <template v-slot:title>
              <span class="font-size-18 font-weight-bold">{{ item.title }}</span>
            </template>
          </b-tab>
          <component :is="tabsComponents[tabIndex].name"></component>
        </b-tabs>
        </div>
      </div>
    <div class="row">
      <div class="col-md-12">
        <pagination
            :page="pagination.page"
            :limit="pagination.pageLimit"
            :totalRecordsCount="pagination.totalRows"
            @onPageChange="handlePageChange"
            @onLimitChange="handleLimitChange"
        />
      </div>
    </div>
  </layout>
</template>
<script>
import Search from '@/components/search.vue';
import { convertDateToFormat } from '@/utils/converters';
import AvatarDefault from '@/assets/images/users/avatar-default.svg';
import MainStaff from "@/components/employees/tabs/MainStaff.vue";
import FreelanceStaff from "@/components/employees/tabs/FreelanceStaff.vue";
import Multiselect from 'vue-multiselect';
import Pagination from "@/components/pagination.vue";

export default {
  name: 'EmployeesPage',
  components: {
    Pagination,
    Search,
    MainStaff,
    FreelanceStaff,
    Multiselect
  },
  data() {
    return {
      AvatarDefault,
      page: 1,
      pageLimit: 20,
      noteModalId: 'note-modal',
      employeeModalId: 'employee-modal',
      tabIndex: 0,
      sort: {
        field: '',
        direction: ''
      },
      pagination: {
        page: 1,
        pageLimit: 20,
        totalRows: 0
      },
      tabsComponents: [
        {
          name: 'MainStaff',
          title: 'Main staff'
        },
        {
          name: 'FreelanceStaff',
          title: 'Freelancers'
        }
      ],
      filters: {
        position: null,
        search: ''
      },
      haveSubordinate: false
    }
  },
  async mounted() {
    this.getFilterFromStore(this.filterEmployees)
    await this.$store.dispatch('team/checkSubordinateStatus').then(res => {
      this.haveSubordinate = res?.data?.message ?? false;
    });
    await this.handleFilter()
  },
  computed: {
    employeesList() {
      return this.$store.getters['employees/employeesList'];
    },
    list() {
      let result = this.employeesList?.data
      if (result && result.length > 0) {
        if (this.filters.position) {
          result = result.filter(e => e.position?.title === this.filters.position)
        }
        if (this.search) {
          result = result.filter(e => e.name.toLowerCase().indexOf(this.search.toLowerCase()) > -1)
        }
        return result;
      }

      return []
    },
    positions() {
      if (this.employeesList?.data && this.employeesList.data.length > 0) {
        return this.employeesList?.data.map(e => {
          return {
            name: e.position?.title,
            value: e.position.id
          }
        })
      }

      return []
    },
    loading() {
      return this.$store.state.employees.loading;
    },
    totalRows() {
      return this.list.length;
    },
    filterEmployees() {
      return this.$store.state.employees.filterEmployees;
    },
    queryParams () {
      return {
        page: this.pagination.page ? this.pagination.page : 1,
        per_page: this.pagination.pageLimit ? this.pagination.pageLimit : 20,
        position_id: this.filters.position ? this.filters.position.value : null,
        s: this.filters.search ? this.filters.search : null
      }
    }
  },
  watch: {
    async tabIndex() {
      await this.handleFilter()
    },
    async 'pagination.page' () {
      await this.handleFilter()
    },
    async 'pagination.pageLimit' () {
      await this.handleFilter()
    },
    async 'filters.position' () {
      await this.handleFilter()
    },
    async 'filters.search' (val) {
      if (val.length === 0) {
        await this.handleFilter()
      }
      if (val.length >= 3) {
        await this.handleFilter()
      }
    }
  },
  methods: {
    getFilterFromStore(filters) {
      if (filters?.position || filters?.search) {
        for (const [key, val] of Object.entries(filters)) {
          this.filters[key] = val
        }
      }
    },
    handlePageChange(page) {
      this.pagination.page = page
    },
    handleLimitChange(limit) {
      this.pagination.pageLimit = limit
    },
    handleSearch(search) {
      this.filters.search = search;
    },
    formatDate(date, format){
      return convertDateToFormat(date, format);
    },
    onDeleted(id) {
      this.$swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('employees/deleteEmployee', {
            id
          })
        }
      })
    },
    async handleScroll() {
      const height = Math.max(
        document.body.scrollHeight, document.documentElement.scrollHeight,
        document.body.offsetHeight, document.documentElement.offsetHeight,
        document.body.clientHeight, document.documentElement.clientHeight
      );
      if (
        window.pageYOffset + 200 >= height - document.documentElement.clientHeight
        && this.page < this.list.last_page
        && !this.loading
      ) {
        ++this.page;
        await this.$store.dispatch('employees/getEmployees', this.queryParams);
      }
    },
    async handleFilter () {
      this.$store.commit('employees/SET_FILTER_EMPLOYEES', this.filters)
      switch (this.tabIndex) {
        case 0:
          await this.$store.dispatch('employees/getSubordinates', this.queryParams).then((res) => {
            if (res.code === 200) {
              this.pagination.totalRows = res?.pagination.total_rows
            }
          })
              break
        case 1:
          await this.$store.dispatch('employees/getFreelancers', this.queryParams).then(res => {
            if (res.code === 200) {
              this.pagination.totalRows = res?.pagination.total_rows
            }
          })
              break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.table {
  thead {
    th {
      cursor: pointer;
    }
  }
  tr {
    position: relative;
  }
}
.link-item {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.employees__item-delete {
  position: relative;
  z-index: 2;
}
.employees__item__image {
  height: 50px;
  width: 50px;
  min-width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50% 50%;
}
.employees__item__image img {
  max-height: 100%;
}
.employees select, .employees input {
  cursor: pointer;
}
.avatar {
  img {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }
}
</style>
